var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),(!_vm.readonly())?_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"form":"notificationEmailForm","color":"primary","type":"submit","loading":_vm.isNotificationEmailSubmitted}},[_vm._v(" "+_vm._s(_vm.$t('btn.save'))+" ")]):_vm._e()],1)],2),_c('v-container',{staticClass:"h-100",attrs:{"id":"notificationEmail","fluid":"","tag":"section"}},[_c('div',[_c('validation-observer',{ref:"validationObserver"},[_c('v-form',{staticClass:"pt-8",attrs:{"id":"notificationEmailForm"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"id":"notificationEmail","color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-email"}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:" text-subtitle-1 pl-2"},[_vm._v(" "+_vm._s(_vm.$t('form.required'))+" ")])]),_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('notification_email.form.title')))])],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('notification_email.form.email_notification_type_document'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.$appConfig.emailNotificationTypeDocuments,"label":_vm.$t('notification_email.form.email_notification_type_document') + '*',"error-messages":errors,"outlined":"","readonly":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("notification_email.type_document." + item)))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("notification_email.type_document." + item)))+" ")]}}],null,true),model:{value:(_vm.emailNotificationTypeDocument),callback:function ($$v) {_vm.emailNotificationTypeDocument=$$v},expression:"emailNotificationTypeDocument"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('notification_email.form.email_notification_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.$appConfig.emailNotificationTypes,"label":_vm.$t('notification_email.form.email_notification_type') + '*',"error-messages":errors,"outlined":"","readonly":_vm.isPermanent},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("notification_email.type." + item)))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("notification_email.type." + item)))+" ")]}}],null,true),model:{value:(_vm.emailNotificationType),callback:function ($$v) {_vm.emailNotificationType=$$v},expression:"emailNotificationType"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('notification_email.form.email_notification_brand'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.$appConfig.emailNotificationBrands,"label":_vm.$t('notification_email.form.email_notification_brand') + '*',"error-messages":errors,"outlined":"","readonly":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("notification_email.brand." + item)))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("notification_email.brand." + item)))+" ")]}}],null,true),model:{value:(_vm.emailNotificationBrand),callback:function ($$v) {_vm.emailNotificationBrand=$$v},expression:"emailNotificationBrand"}})]}}])})],1),(!_vm.isPermanent)?_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('select-search-training-type',{attrs:{"show-specific-toyota":false,"outlined":"","clearable":"","hide-details":"auto","rules":"required"},model:{value:(_vm.trainingType),callback:function ($$v) {_vm.trainingType=$$v},expression:"trainingType"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('notification_email.form.object'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('notification_email.form.object') + '*',"outlined":"","solo":"","readonly":_vm.readonly()},model:{value:(_vm.object),callback:function ($$v) {_vm.object=$$v},expression:"object"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('notification_email.form.content'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":_vm.$t('notification_email.form.content') + '*',"outlined":"","readonly":_vm.readonly()},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})]}}])})],1)],1)],1)],2)],1)],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"id":"variablesNotificationEmail","color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-cog"}},[_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('notification_email.form.title_variables'))+" ")])],1),_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.headersVariables,"items":_vm.getVariables,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('notification_email.list.variables.' + item)))]),_c('td',[_c('span',[_vm._v("{{")]),_vm._v(" "+_vm._s(item)+" "),_c('span',[_vm._v("}}")])])])]}}])})],2),_c('cancel-update-dialog',{attrs:{"saving":_vm.isNotificationEmailSubmitted},on:{"cancel":function($event){return _vm.onCancel()},"save":function($event){return _vm.onSave()}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }