































































































































































































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import cancelSaveDialog from '@/mixins/cancelSaveDialog'
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapState, mapGetters } from 'vuex'
import { RawLocation } from 'vue-router'
import { isReadonly } from '@/composables/UserGrant'
import SelectSearchTrainingType from '@/components/molecules/select-search/trainingType.vue'
import { EmailNotificationTypeDocument } from '@/api/enums/emailNotificationTypeDocument'
import { NotificationEmail } from '@/api/interfaces/notificationemail'
import { EmailNotificationBrand } from '@/api/enums/emailNotificationBrand'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'NotificationEmail',
  components: {
    SelectSearchTrainingType,
    TopBar,
    CancelUpdateDialog: () => import('@/components/cancel-update-dialog.vue'),
    TimeField: () => import('@/components/base/TimeField.vue'),
  },
  data () {
    return {
      title: this.$t('notification_email.form.title'),
      errors: [],
      test: {},
      headersVariables: [
        {
          text: this.$t('notification_email.list.headers_variables.description'),
          align: 'start',
          value: 'description',
        },
        {
          text: this.$t('notification_email.list.headers_variables.variable'),
          align: 'start',
          value: 'variable',
        },
      ],
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    ...mapFields('notificationEmailForm', [
      'notificationEmail.object',
      'notificationEmail.content',
      'notificationEmail.emailNotificationTypeDocument',
      'notificationEmail.emailNotificationType',
      'notificationEmail.emailNotificationBrand',
      'notificationEmail.trainingType',
      'notificationEmail.isPermanent',
    ]),
    ...mapState('notificationEmailForm', {
      notificationEmail: 'notificationEmail',
      isNotificationEmailSubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
    ...mapGetters('notificationEmailForm', {
      getVariables: 'getVariables',
    }),
    idNotificationEmail () {
      return this.$route.params.idNotificationEmail || null
    },
  },
  async created () {
    await this.init()
  },
  methods: {
    async init () {
      await this.reset()
      if (this.idNotificationEmail) {
        await this.loadNotificationEmailById(
          this.idNotificationEmail,
        )
      } else {
        (this.notificationEmail as NotificationEmail).emailNotificationTypeDocument = EmailNotificationTypeDocument.CONVOCATION;
        (this.notificationEmail as NotificationEmail).emailNotificationBrand = EmailNotificationBrand.GENERIC
      }
    },
    ...mapActions('notificationEmailForm', {
      save: 'save',
      loadNotificationEmailById: 'loadById',
      reset: 'reset',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(
        this.$refs.validationObserver,
      )
      if (isValidatedForm) {
        await this.save(this.notificationEmail)
        await this.sendSuccessMessage('notification_email.form.saved')
        this.returnPrevious()
      }
    },
    cancel () {
      this.returnPrevious()
    },
    returnPrevious () {
      if (this.getLastSavedRoute) {
        this.$router.push(this.getLastSavedRoute as RawLocation)
      } else {
        this.$router.push({ name: 'NotificationEmail List' })
      }
    },
  },
})
